import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Cpet from "../Upages/Cpet";
import { Helmet } from 'react-helmet';

const Pet = () =>{

    return(
        <>
         <Helmet>
        <title>Society Rules at Escape Nirvana</title>
        <meta name="title" property="og:title" content="Society Rules at Escape Nirvana" />
        <meta name="description" content="Discover the community guidelines and rules governing Escape Nirvana society, ensuring a harmonious living environment for all residents." />
        <meta property="og:description" content="Discover the community guidelines and rules governing Escape Nirvana society, ensuring a harmonious living environment for all residents." />
      </Helmet>
      <Navbar />
     
        <Cpet />
        <Footer/>
        </>
    );
}
export default Pet;