import React from "react";
import { Link } from "react-router-dom";
const Animities=() =>{
    return(
        <>
         <section className="lifea">
        <div className="container">
          <div className="row">
            <div className="col-12" style={{ backgroundColor: '#0e0e0e38' }}>
              <h2 className="text-danger text-center mt-3 ">Lifestyle Amenities</h2>
              <p style={{ color: 'white', fontWeight: 'normal', textAlign: 'center' }}>There are some unique
                facilities such as RO Water System,Conference Room...</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 col-12 mt-3 ">
              <div className="row" id="Amenities">
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/security-man.png" alt="" width={40} className="mt-2" />
                  <p className="pi mt-1">Security</p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/lift.png" alt="" width={40} className="mt-2" />
                  <p className="pi mt-1">Lift </p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/park.png" alt="" width={40} className="mt-2" />
                  <p className="pi mt-1">Park </p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/paying-car-ticket_75737.png" alt="" width={40} className="mt-2" />
                  <p className="pi mt-1">Parking </p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/club.png" alt="" width={40} className="mt-2" />
                  <p className="pi mt-1">Club </p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/flower gardeen.png" alt="" width={40} className="mt-2" />
                  <p className="pi mt-1">Gardens </p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/jogging.png" alt="" width={40} className="mt-2" />
                  <p className="pi mt-1">Jogging </p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/kids play.png" alt="" width={40} className="mt-2" />
                  <p className="pi mt-1">Kids Pool </p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/backup.png" alt="" width={40} className="mt-2" />
                  <p className="pi mt-1">Back Up </p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/wifi.png" alt="" width={40} className="mt-2" />
                  <p className="pi mt-1">Internet</p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/gym.png" alt="" width={40} className="mt-2" />
                  <p className="pi mt-1">Gym</p>
                </div>
                <div className="col-2" id="ic">
                  <img src="assets/images/icon/water.png" alt="" width={40} className="mt-2" />
                  <p className="pi mt-1"> Ro Water</p>
                </div>
              </div>
              <a href data-bs-toggle="modal" data-bs-target="#exampleModal2" className="btn btn-danger mt-4 mb-4"> Show
                All 34 Amenities</a>
            </div>
            <div className="col-lg-5 col-12 mt-3 mb-4">
              <div className="box p-4 m-0 " style={{ backgroundColor: '#d3d3d3' }}>
                <h5 className="text-center">Club House Details</h5>
                <hr />
                <div className="row">
                  <div className="col-6">
                    <a href> 1. FRONT DESK </a>
                    <hr />
                    <a href > 2. LOBBY AREA </a>
                    <hr />
                    <a href > 3. PARTY HALL </a>
                    <hr />
                    <a href > 4. SITTING AREA </a>
                    <hr />
                  </div>
                  <div className="col-6">
                    <a href > 5. SWIMMING POOL </a>
                    <hr />
                    <a href > 6. PARTY HALL</a>
                    <hr />
                    <a href > 7. SPORTS ROOM</a>
                    <hr />
                    <a href> 8. GYM </a>
                    <hr />
                  </div>
                </div>
                <div className="col-12">
                  <a href className="text-danger"> See all &nbsp;<i className="bi bi-arrow-right" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* POPUP Amenities */}
        <div className="modal fade" id="exampleModal2" tabIndex={-1} aria-labelledby="exampleModalLabel2" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header bg-info">
                <h4 className="modal-title radiant" id="exampleModalLabel2">Lifestyle Amenities</h4>
                <button type="button" className="btn btn-outline-danger" id="btn4" data-bs-dismiss="modal" aria-label="Close"> <b>X</b></button>
              </div>
              <div className="modal-body">
              <div className="row mt-3 mb-4">
            <div className="col">
              <Link to='' id="storea">Multipurpose Hall </Link>
              <Link to='' id="storea">Earth quake resistant</Link>
              <Link to='' id="storea">Multipurpose Courts</Link>
              <Link to='' id="storea">Humara Brandistan</Link>
              <Link to='' id="storea"> Electronics line</Link>
              <Link to='' id="storea">Meditation Area</Link>
              <Link to='' id="storea">Indoor Squash &amp; Badminton Courts</Link>
              <Link to='' id="storea">Flower Gardens</Link>
              <Link to='' id="storea">Park</Link>
              <Link to='' id="storea">Laundry Service</Link>
              <Link to='' id="storea">Reserved Parking</Link>
              <Link to='' id="storea"> Visitor Parking</Link>
              <Link to='' id="storea">Internet/Wi-Fi Connectivity</Link>
              <Link to='' id="storea"> Lift</Link>
              <Link to='' id="storea">Vaastu Compliant</Link>
              <Link to='' id="storea">Conference Room</Link>
              <Link to='' id="storea">Intercom Facility</Link>
              <Link to='' id="storea"> Banquet Hall</Link>
              <Link to='' id="storea"> Maintenance Staff</Link>
              <Link to='' id="storea"> Activity Deck4</Link>
              <Link to='' id="storea">Contact Seller</Link>
              <Link to='' id="storea">New Launch</Link>
              <Link to='' id="storea">Kids Play Pool With Water Slides</Link>
              <Link to='' id="storea">AEROBICS ROOM</Link>
              <Link to='' id="storea">Event Space &amp; Amphitheatre</Link>
              <Link to='' id="storea">Expert Reviews &amp; Advice</Link>
              <Link to='' id="storea"> Latest RERA Reports</Link>
              <Link to='' id="storea"> Fire Fighting Equipment</Link>
              <Link to='' id="storea">Gymnasium</Link>
              <Link to='' id="storea">Water Storage</Link>
              <Link to='' id="storea">RO Water System</Link>
              <Link to='' id="storea">Indoor Games Room</Link>
              
            </div>

          </div>
               
            
          </div>
               
              
            </div>
          </div>
        </div>
        </section>
        
        </>
    )
}
export default Animities;